import { InputFields } from './LeftColumn/Inputs';
import { OutputFields } from './RightColumn/Outputs';
import React, { useState } from 'react';
import { ReportPageProps } from './ReportType';
import { WeekNumberType } from './LeftColumn/InputType';

export function ReportPage(props: ReportPageProps) {
  const [weeks, setWeeks] = useState<WeekNumberType[]>([]);

  return (
    <>
      <InputFields
        inputData={props.inputData}
        updateInputs={props.updateInputs}
        updateMessageQueue={props.updateMessageQueue}
        allPlannings={props.allPlannings}
        weeks={weeks}
        setWeeks={setWeeks}
      ></InputFields>
      <OutputFields
        inputData={props.inputData}
        changeInputs={props.changeInputs}
        setChangeInputs={props.setChangeInputs}
        updateMessageQueue={props.updateMessageQueue}
        rates={props.rates}
        phases={props.phases}
        weeks={weeks}
      />
    </>
  );
}
