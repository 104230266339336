import {
  ProjectDataType,
  ProjectNumberProps,
} from './Report/LeftColumn/InputType';
import { AutoComplete } from './AutoComplete/AutoComplete';
import { styled } from 'styled-components';

const ProjectNumberDiv = styled.div<{
  isInputFieldSelected?: boolean;
}>`
  width: 350px;
  height: 40px;
  background: white;
`;

export function ProjectNumberInput(props: ProjectNumberProps) {
  return (
    <ProjectNumberDiv>
      <AutoComplete
        options={props.projects}
        optionLabels={(project: ProjectDataType) =>
          project.ProjectNumber + ' ' + project.ProjectName
        }
        onChange={(value) => {
          if (value && value.ProjectNumber) {
            props.updateInputs({
              projectId: value.ProjectId,
              projectNumber: value.ProjectNumber,
              currency: value.CurrencySymbol,
              weekNumber: '',
              selectedPhases: [],
              allPhases: value.PhaseName,
              planning: { id: '', project_id: '', name: '' },
              filename: '',
              startDate: value.StartDate,
            });
          }
        }}
        placeholder={'Select Project'}
        disabled={props.disabled}
      />
    </ProjectNumberDiv>
  );
}
