import React, {
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { usePlanningUpdates } from '../Planning.hooks';
import {
  generateColumnsForWeekRange,
  getBaseColumns,
  getColumns,
  TableIconButton,
} from './Columns';
import {
  DataGridPremium,
  GRID_AGGREGATION_FUNCTIONS,
  GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD,
  GridAggregationFunction,
  GridAggregationModel,
  GridCellParams,
  gridClasses,
  GridColDef,
  GridColumnVisibilityModel,
  GridTreeNode,
  GridValidRowModel,
  useKeepGroupedColumnsHidden,
} from '@mui/x-data-grid-premium';
import { ColumnValue } from '../Functions/GridFunctions';
import { PlanningSpent } from '../../../Settings';
import { addDaysToDateString } from '../Functions/TimeFunctions';
import {
  PhaseCodeEntry,
  PhaseEntry,
  PlanningType,
  RateEntry,
  RowEntry,
} from '../PlanningType';
import { EditToolbar } from '../Toolbar/Toolbar';
import { ReactComponent as PlusIcon } from '../../../assets/Plus.svg';
import {
  InputDataType,
  MessageQueueType,
} from '../../Report/LeftColumn/InputType';
import { CustomColumnMenu, CustomColumnMenuIcon } from './CustomColumnMenu';

interface DataGridProps {
  inputData: InputDataType;
  rows: RowEntry[];
  setUpdatedRows: Dispatch<SetStateAction<RowEntry[]>>;
  actualCosting: PhaseEntry[];
  selectedPlanning: PlanningType | null;
  setSelectedPlanning: (selectedPlanning: PlanningType | null) => void;
  phases: PhaseCodeEntry;
  rates: RateEntry;
  inEditMode: boolean;
  setIsloading: (p: boolean) => void;
  allPlannings: PlanningType[];
  setAllPlannings: (p: PlanningType[]) => void;
  updateMessageQueue: (newMessage: MessageQueueType) => void;
  alreadyInUse: boolean;
  setChangeIsMade: Dispatch<SetStateAction<boolean>>;
}

const initialVisibilityModel: GridColumnVisibilityModel = {
  part: false,
};

export const PlanningTable = memo(function DataGrid(props: DataGridProps) {
  const { apiRef, addRow, deleteRow, copyRow } = usePlanningUpdates(
    props.rows,
    props.setUpdatedRows,
    props.setChangeIsMade
  );
  const [selectedWeek, setSelectedWeek] = useState<Date | null>(null);
  const [weeklyPlanning, setWeeklyPlanning] = useState(true);

  const [baseColumns, setBaseColumns] = useState<GridColDef<RowEntry>[]>([]);
  useEffect(() => {
    setBaseColumns(
      getBaseColumns(
        addRow,
        deleteRow,
        copyRow,
        props.rates,
        props.phases,
        props.inputData.currency,
        props.actualCosting,
        props.inEditMode
      )
    );
  }, [props.selectedPlanning, props.actualCosting, props.inEditMode]);

  const [columns, setColumns] = useState<GridColDef<RowEntry>[]>([]);

  useEffect(() => {
    if (
      Object.keys(props.rates).length !== 0 &&
      Object.keys(props.phases).length !== 0 &&
      selectedWeek &&
      props.rows.length !== 0
    ) {
      const newColumns = getColumns({
        addRow,
        deleteRow,
        copyRow,
        rates: props.rates,
        rows: props.rows,
        setVisibilityModel,
        setAggregationModel,
        weeklyPlanning: weeklyPlanning,
        phases: props.phases,
        currency: props.inputData.currency,
        selectedWeek: selectedWeek,
        actualCosting: props.actualCosting,
        allowedToEdit: props.inEditMode,
      });
      setColumns(newColumns);
    } else {
      setColumns([]);
    }
  }, [props.rows, selectedWeek, props.inEditMode]);

  const addWeekColumns = useCallback(() => {
    if (columns.length > 0) {
      props.setIsloading(true);
      const lastColumn = columns[columns.length - 1];
      const startDate = addDaysToDateString(lastColumn.field);

      const newColumns = generateColumnsForWeekRange({
        startDate,
        maxDate: startDate,
        rates: props.rates,
        setAggregationModel: setAggregationModel,
        setVisibilityModel: setVisibilityModel,
        weeklyPlanning: weeklyPlanning,
        allowedToEdit: props.inEditMode,
      });

      setColumns((oldColumns) => [...oldColumns, ...newColumns]);
      // Re-enable the button after 1 second (1000 milliseconds)
      setTimeout(() => {
        props.setIsloading(false);
      }, 200);
    }
  }, [columns, weeklyPlanning]);

  const NewWeekColumn: GridColDef<RowEntry> = {
    field: 'new-week',
    type: 'string',
    width: 50,
    editable: false,
    filterable: false,
    disableReorder: true,
    sortable: false,
    disableColumnMenu: true,
    renderHeader: () => (
      <TableIconButton
        hoverColor={'var(--twd_aqua)'}
        normalColor={'var(--twd_web_grey)'}
        disabled={!props.inEditMode}
        onClick={addWeekColumns}
      >
        <PlusIcon />
      </TableIconButton>
    ),
  };

  const [visibilityModel, setVisibilityModel] = useState(
    initialVisibilityModel
  );

  const [aggregationModel, setAggregationModel] =
    useState<GridAggregationModel>({
      planned: 'valueWeek',
      total: 'valueWeek',
    });

  const valueWeekAggregation: GridAggregationFunction<ColumnValue, string> = {
    label: '',
    apply: (params) => {
      if (!params.values) {
        return '';
      }
      const SpentAddedPerPhase: string[] = [];
      let valueSum: number = params.values.reduce((sum: number, value) => {
        let newValue = (value?.value || 0) * (value?.rate || 0);
        if (
          value?.spent &&
          value?.spent > 0 &&
          !SpentAddedPerPhase.includes(value?.partId)
        ) {
          newValue += value?.spent;
          SpentAddedPerPhase.push(value?.partId);
        }
        return (sum || 0) + (newValue || 0);
      }, 0);
      // Call setAggregatedPlanned to store planned per week or per phase in Graph props
      // setAggregatedPlanned(params.field, String(params.groupId), valueSum);
      if (valueSum === 0) return '0';
      return valueSum?.toLocaleString('nl-NL', {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      });
    },
    valueFormatter: (value) => props.inputData.currency + ' ' + value,
    columnTypes: ['number'],
  };

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: ['part'],
      },
      pinnedColumns: {
        left: [
          GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD,
          'color',
          'task',
          'actions',
          'rate',
          'employee',
          `${PlanningSpent}`,
        ],
        right: ['new-week', 'planned', 'total'],
      },
    },
  });

  return (
    <>
      <EditToolbar
        apiRef={apiRef}
        columns={columns}
        rows={props.rows}
        setVisibilityModel={setVisibilityModel}
        rates={props.rates}
        allPlannings={props.allPlannings}
        setAllPlannings={props.setAllPlannings}
        planningId={props.selectedPlanning}
        setPlanningId={props.setSelectedPlanning}
        weeklyPlanning={weeklyPlanning}
        setWeeklyPlanning={setWeeklyPlanning}
        setIsloading={props.setIsloading}
        projectId={props.inputData.projectId}
        startDate={props.inputData.startDate}
        setSelectedWeek={setSelectedWeek}
        updateMessageQueue={props.updateMessageQueue}
        inEditMode={props.inEditMode}
        alreadyInUse={props.alreadyInUse}
      />
      <DataGridPremium
        sx={{
          border: 0,
          '& .MuiDataGrid-main': {
            border: 'solid 1px var(--twd_border_grey)',
          },
          '& .MuiDataGrid-cell': { padding: '0 5px' },
          '& .MuiDataGrid-columnHeader': {
            background: 'var(--twd_aqua_pale_2)',
          },
          '& .MuiDataGrid-columnHeaders .MuiDataGrid-filler': {
            background: 'var(--twd_aqua_pale_2)',
          },
          ...CellColors,
        }}
        apiRef={apiRef}
        initialState={initialState}
        rows={props.rows}
        rowHeight={30}
        getRowClassName={(params) =>
          `super-app-theme--${params.row.spent && params.row.spent >= 0 ? `${PlanningSpent}` : ''}`
        }
        columns={[...baseColumns, ...columns, NewWeekColumn]}
        disableColumnFilter
        cellSelection
        rowSelection={false}
        hideFooter={true}
        processRowUpdate={(newRow, oldRow) => {
          props.setUpdatedRows((oldUpdatedRows: RowEntry[]) =>
            oldUpdatedRows.map((oldUpdatedRow: RowEntry) => {
              if (oldUpdatedRow.id === newRow.id) {
                return newRow; // Replace the row with the updated row
              }
              return oldUpdatedRow; // Return the original row if it doesn't match
            })
          );
          props.setChangeIsMade(true);
          return newRow;
        }}
        columnVisibilityModel={visibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setVisibilityModel(newModel)
        }
        aggregationModel={aggregationModel}
        onAggregationModelChange={(newModel) => setAggregationModel(newModel)}
        aggregationFunctions={{
          ...GRID_AGGREGATION_FUNCTIONS,
          valueWeek: valueWeekAggregation,
        }}
        groupingColDef={{
          hideDescendantCount: true, // Hide the number next to the group
        }}
        getCellClassName={(
          params: GridCellParams<
            any,
            GridValidRowModel,
            GridValidRowModel,
            GridTreeNode
          >
        ) => {
          if (
            [
              'color',
              'task',
              'actions',
              'rate',
              'employee',
              `${PlanningSpent}`,
            ].includes(params.field) &&
            params.row.color !== 'no color'
          ) {
            return params.row.color;
          }
          return '';
        }}
        slots={{
          columnMenu: CustomColumnMenu,
          columnMenuIcon: CustomColumnMenuIcon,
        }}
      />
    </>
  );
});

const CellColors = {
  [`.${gridClasses.cell}.green`]: {
    backgroundColor: 'green',
  },
  [`.${gridClasses.cell}.red`]: {
    backgroundColor: 'red',
  },
  [`.${gridClasses.cell}.blue`]: {
    backgroundColor: 'lightblue',
  },
};
