import React, { ChangeEvent, MouseEvent, useState } from 'react';
import { PlanningType } from '../../../PlanningType';
import { EnterNameModal } from '../EnterNameModal';
import { styled } from 'styled-components';
import { ReactComponent as CrossIcon } from '../../../../../assets/Cross.svg';
import { ReactComponent as CopyIcon } from '../../../../../assets/Copy.svg';
import { ReactComponent as EditIcon } from '../../../../../assets/Edit.svg';
import { PlanningButtonContentProps } from './PlanningButtonOperations.types';
import { IconButton } from '../../../../IconButton/IconButton';

export function PlanningButtonOperations<T extends object>(
  props: PlanningButtonContentProps<T>
) {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [planningName, setPlanningName] = useState<string>('');
  const onEditButtonClicked = (event: MouseEvent<HTMLElement>) => {
    setPlanningName((props.option.option as PlanningType).name);
    setOpenModal(true);
    event.stopPropagation();
  };

  const changePlanningName = (event: ChangeEvent<HTMLInputElement>) => {
    setPlanningName(event.target.value);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const changePlanningNameButtonClicked = async () => {
    await props.editPlanningName(
      props.option.option as PlanningType,
      planningName
    );
    closeModal();
  };

  const clickOnCopyPlanning = async (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    await props.copyPlanning(props.option.option as PlanningType);
    props.changeSelectedOption(-1);
  };

  const clickOnDeletePlanning = async (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    await props.deletePlanning(props.option.option as PlanningType);
    props.changeSelectedOption(0);
  };

  return (
    <IconButtonDiv>
      <EnterNameModal
        value={planningName}
        buttonName={'Change name of this planning'}
        openModal={openModal}
        closeModal={closeModal}
        changePlanningName={changePlanningName}
        CreatePlanningButtonOnClick={changePlanningNameButtonClicked}
      />
      <IconButton hoverColor={'var(--twd_aqua)'} onClick={onEditButtonClicked}>
        <EditIcon />
      </IconButton>
      <IconButton
        hoverColor={'var(--twd_aqua)'}
        onClick={async (e: MouseEvent<HTMLElement>) =>
          await clickOnCopyPlanning(e)
        }
      >
        <CopyIcon />
      </IconButton>
      <IconButton
        hoverColor={'var(--twd_red)'}
        onClick={async (event: any) => await clickOnDeletePlanning(event)}
      >
        <CrossIcon />
      </IconButton>
    </IconButtonDiv>
  );
}

const IconButtonDiv = styled.div`
  display: flex;
  align-items: center;
  z-index: 11;
`;
