import { useEffect, useState } from 'react';
import { DetailedPhasesInput, PhasesInput } from './DetailsPerPhase';
import { getFromAPI } from '../../../services/apiCalls';
import {
  InputFieldsProps,
  PlanningInputProps,
  WeekNumberProps,
  WeekNumberType,
} from './InputType';
import { styled } from 'styled-components';
import { AutoComplete } from '../../AutoComplete/AutoComplete';
import { PlanningType } from '../../Planning/PlanningType';

const CardDiv = styled.div`
  background-color: white;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: inset 0 0 0 1px var(--twd_web_grey);
`;

const InputDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 50px;
  gap: 10px;
  width: 100%;
`;

const WrappedAutoCompleteDiv = styled.div`
  height: 50px;
  width: 100%;
  min-width: 150px;
  background: white;
`;

function WeekNumberInput(props: WeekNumberProps) {
  useEffect(() => {
    if (props.weeks.length > 0) {
      const newMaxWeek = props.weeks[props.weeks.length - 1];
      props.updateInputs({
        weekNumber: newMaxWeek.YearWeek,
        selectedPhases: [],
      });
    }
  }, [props.weeks]);

  return (
    <WrappedAutoCompleteDiv>
      <AutoComplete
        options={props.weeks}
        optionLabels={(week: WeekNumberType) => week.YearWeek}
        onChange={(value) => {
          if (value && value.YearWeek) {
            props.updateInputs({
              weekNumber: value.YearWeek,
              selectedPhases: [],
            });
            props.setHasDetailedPhases(false);
          }
        }}
        placeholder={'Select Week'}
        autoSelectIndex={-1}
        disabled={props.inputData.projectId === ''}
      />
    </WrappedAutoCompleteDiv>
  );
}

function PlanningInput(props: PlanningInputProps) {
  const [reportPlanningOptions, setReportPlanningOptions] = useState<
    PlanningType[]
  >([]);
  const NoPlanning: PlanningType = {
    id: '-1',
    project_id: '-1',
    name: 'No Planning',
  };

  useEffect(() => {
    if (props.projectId) {
      let newPlannings = [NoPlanning];
      if (props.allPlannings.length > 0) {
        newPlannings = [...newPlannings, ...props.allPlannings];
      }
      setReportPlanningOptions(newPlannings);
    }
  }, [props.allPlannings]);

  return (
    <WrappedAutoCompleteDiv>
      <AutoComplete
        options={reportPlanningOptions}
        optionLabels={(planning: PlanningType) => planning.name}
        onChange={(planning: PlanningType | null) => {
          props.updateInputs({
            planning: planning || { id: '', project_id: '', name: '' },
          });
        }}
        placeholder={'Select Planning'}
        autoSelectIndex={0}
        disabled={
          props.projectId === '' ||
          (props.lastWeek &&
            props.inputData.weekNumber !== props.lastWeek.YearWeek)
        }
      />
    </WrappedAutoCompleteDiv>
  );
}

export function InputFields(props: InputFieldsProps) {
  const [hasDetailedPhases, setHasDetailedPhases] = useState<boolean>(false);

  useEffect(() => {
    setHasDetailedPhases(false);
    if (props.inputData.projectNumber) {
      getFromAPI('/budget-report/projects/' + props.inputData.projectNumber)
        .then(async (response) => {
          const responseJson = await response.json();
          if (response.ok) {
            props.setWeeks(responseJson.data);
          } else {
            props.updateMessageQueue({
              message: responseJson.detail.message,
              kind: 'error',
            });
            props.setWeeks([]);
          }
        })
        .catch((err) => {
          props.setWeeks([]);
        });
    }
  }, [props.inputData.projectNumber]);

  const updatePhaseList = (newPhase: string) => {
    // Function that selects phases (included in report).
    let phasesList = props.inputData.selectedPhases;
    const phaseIndex = phasesList.indexOf(newPhase);

    if (phaseIndex !== -1) {
      phasesList.splice(phaseIndex, 1);
    } else {
      phasesList.push(newPhase);
    }

    props.updateInputs({
      selectedPhases: phasesList,
    });
  };

  return (
    <CardDiv>
      <InputDiv>
        <WeekNumberInput
          updateInputs={props.updateInputs}
          inputData={props.inputData}
          weeks={props.weeks}
          setHasDetailedPhases={setHasDetailedPhases}
        />
        <PlanningInput
          allPlannings={props.allPlannings}
          projectId={props.inputData.projectId}
          inputData={props.inputData}
          updateInputs={props.updateInputs}
          lastWeek={props.weeks[props.weeks.length - 1]}
        />
        <PhasesInput
          updateInputs={props.updateInputs}
          inputData={props.inputData}
          weeks={props.weeks}
          hasDetailedPhases={hasDetailedPhases}
          setHasDetailedPhases={setHasDetailedPhases}
          updatePhaseList={updatePhaseList}
        />
      </InputDiv>
      <DetailedPhasesInput
        inputData={props.inputData}
        hasDetailedPhases={hasDetailedPhases}
        updatePhaseList={updatePhaseList}
      />
    </CardDiv>
  );
}
