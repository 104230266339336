import {
  GridColDef,
  GridColumnVisibilityModel,
} from '@mui/x-data-grid-premium';

import { postFromAPI } from '../../../services/apiCalls';
import { EditToolbarProps } from './ToolBarType';
import {
  convertToDatabase,
  getPlanningEntries,
} from '../Functions/DatabaseFunctions';
import { PlanningSelect } from './PlanningSelect/PlanningSelect';
import { WeekSelect } from './WeekSelect/WeekSelect';
import { ToggleButtons } from '../../ToggleButtons/ToggleButton';
import { InputEntry, RowEntry } from '../PlanningType';
import { styled } from 'styled-components';

const CustomToolbar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 4px 0 0 0;
`;

function visibilityLogic(weeklyView: boolean, column: GridColDef): boolean {
  if (
    column.cellClassName === 'weekColumn' ||
    column.cellClassName === 'weekColumn-past'
  )
    return true;
  if (
    column.cellClassName === 'dayColumn' ||
    column.cellClassName === 'dayColumn-past'
  )
    return !weeklyView;
  // Also hide the part since it is grouped.
  return column.field !== 'part';
}

export function EditToolbar(props: EditToolbarProps) {
  const togglePlanningView = (weeklyView: boolean) => {
    if (weeklyView !== null) {
      props.setWeeklyPlanning(weeklyView);

      let newVisibilityModel: GridColumnVisibilityModel = {};
      props.columns.map((column) => {
        newVisibilityModel[column.field] = visibilityLogic(weeklyView, column);
      });
      props.setVisibilityModel((oldVisibilityModel) => ({
        ...oldVisibilityModel,
        ...newVisibilityModel,
      }));
    }
  };

  const saveCopiedPlanning = async (
    oldPlanningId: string,
    newPlanningId: string
  ) => {
    const planningEntries = await getPlanningEntries(oldPlanningId);
    console.log(planningEntries);
    const payload = planningEntries.map((planningEntry: InputEntry) => {
      return { ...planningEntry, planning_id: newPlanningId };
    });
    console.log(payload);
    const response = await postFromAPI('/budget-planning/entries/bulk-upload', {
      entries: payload,
    });
    if (response.ok) {
      console.log('helemaal goed G');
    } else {
      console.log('Ohh narr');
    }
    props.setIsloading(false);
  };

  return (
    <CustomToolbar>
      <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
        <PlanningSelect
          planningId={props.planningId}
          allPlannings={props.allPlannings}
          setAllPlannings={props.setAllPlannings}
          setPlanningId={props.setPlanningId}
          setIsLoading={props.setIsloading}
          projectId={props.projectId}
          updateMessageQueue={props.updateMessageQueue}
          saveCopiedPlanning={saveCopiedPlanning}
          inEditMode={props.inEditMode}
        />
        <WeekSelect
          startDate={props.startDate}
          setSelectedWeek={props.setSelectedWeek}
          projectId={props.projectId}
        />
        {props.planningId && props.planningId.last_edited_by && (
          <div>
            <p style={{ color: 'green' }}>
              Last edited by: <b>{props.planningId.last_edited_by}</b>
            </p>
          </div>
        )}
        {props.planningId &&
          props.planningId.last_edited_by &&
          props.alreadyInUse && <p style={{ color: 'green' }}>|</p>}
        {props.alreadyInUse && (
          <div>
            <p style={{ color: 'green' }}>
              Currently edited by:{' '}
              <b>{props.planningId && props.planningId.editor}</b>
            </p>
          </div>
        )}
      </div>
      <ToggleButtons
        firstButtonText={'Week'}
        secondButtonText={'Day'}
        value={props.weeklyPlanning}
        onChange={togglePlanningView}
      />
    </CustomToolbar>
  );
}
