import React, { useEffect, useState } from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { BarChart } from '@mui/x-charts/BarChart';
import { GraphData as GraphDataType, GraphProps, WeekEntry } from './GraphType';
import { ToggleButtons } from '../../ToggleButtons/ToggleButton';
import { getFromAPI } from '../../../services/apiCalls';
import { addDays, isAfter, isBefore, startOfWeek } from 'date-fns';
import {
  changeDateToWeek,
  fromStringToDate,
  weekStringToDate,
} from '../Functions/TimeFunctions';
import { RowEntry } from '../PlanningType';
import { getMaxPlannedDate } from '../PlanningTable/Columns';
import { styled } from 'styled-components';

export function Graph(props: GraphProps) {
  const [weekBudgets, setWeekBudgets] = useState<WeekEntry[]>([]);
  const [weekSpentList, setWeekSpentList] = useState<WeekEntry[]>([]);
  const [graphData, setGraphData] = useState<GraphDataType | null>(null);
  const [totalGraph, setTotalGraph] = useState<boolean>(true);

  useEffect(() => {
    const GetProjectData = async () => {
      // Get budget per week
      const weekBudgetResponse = await getFromAPI(
        '/budget-planning/weeks/cost-estimate/' + props.inputData.projectId
      );
      const allWeekBudget = (await weekBudgetResponse.json()).data;

      // Get spent per weeks
      const weekSpentResponse = await getFromAPI(
        '/budget-planning/weeks/actual-costing/' + props.inputData.projectId
      );
      const allWeekSpent = (await weekSpentResponse.json()).data;

      setWeekBudgets(allWeekBudget);
      setWeekSpentList(allWeekSpent);
    };
    if (props.inputData.projectId !== '') {
      // Invoke the function to fetch data
      void GetProjectData();
    }
  }, [props.inputData.projectId]);

  const handleToggleChange = () => {
    // Toggle the value of props.totalGraph to switch between Weekly/Total graph when clicking the button
    setTotalGraph(!totalGraph);
  };

  useEffect(() => {
    const spentList = [];
    const cumulativeSpentList = [];
    let cumulativeSpent = 0;

    const cumulativePlannedList = [];
    let cumulativePlanned = 0;

    const weeks = [];
    const plannedList = [];
    let beginDate = new Date(props.inputData.startDate);
    beginDate = startOfWeek(beginDate, { weekStartsOn: 1 });

    const cumulativeBudgets = [];
    let cumulativeBudget = weekBudgets.reduce((sum, acc) => {
      if (isBefore(weekStringToDate(acc.week), beginDate)) {
        sum += acc.value;
      }
      return sum;
    }, 0);

    if (props.updatedRows.length > 0) {
      const currentRows = props.updatedRows;
      const maxPlannedDate = getMaxPlannedDate(currentRows);
      while (
        isBefore(beginDate, maxPlannedDate) ||
        beginDate === maxPlannedDate
      ) {
        const weekString = changeDateToWeek(beginDate);
        weeks.push(weekString);

        const thisWeekString = changeDateToWeek(new Date());
        const thisWeekDate = weekStringToDate(thisWeekString);
        const budget = weekBudgets.find(
          (weekBudget) => weekBudget.week === weekString
        );
        cumulativeBudget += budget ? budget.value : 0;
        cumulativeBudgets.push(cumulativeBudget);

        const spent = weekSpentList.find(
          (weekSpent) => weekSpent.week === weekString
        );
        spentList.push(spent ? spent.value : 0);
        const planned = props.updatedRows.reduce((sum, row: RowEntry) => {
          const {
            id,
            part,
            partId,
            color,
            employee,
            task,
            rate,
            spent,
            ...columnEntries
          } = row;
          const thisWeekList = Object.entries(columnEntries).filter(
            ([key, _]) => {
              const keyDate = fromStringToDate(key);
              const keyString = changeDateToWeek(keyDate);
              return keyString === weekString;
            }
          );
          sum += thisWeekList.reduce((innerSum, week) => {
            innerSum += week[1] * (rate ? props.rates[rate] : 1);
            return innerSum;
          }, 0);
          return sum;
        }, 0);
        plannedList.push(planned);

        if (weekString === thisWeekString) {
          cumulativePlanned += planned;
          cumulativePlannedList.push(cumulativePlanned);
        }
        if (isBefore(beginDate, thisWeekDate)) {
          cumulativeSpent += spent ? spent.value : 0;
          cumulativeSpentList.push(cumulativeSpent);

          const oneWeekAgo = thisWeekDate;
          oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
          if (weekString === changeDateToWeek(oneWeekAgo)) {
            cumulativePlanned += cumulativeSpent;
            cumulativePlannedList.push(cumulativePlanned);
          } else {
            cumulativePlannedList.push(null);
          }
        }
        if (isAfter(beginDate, thisWeekDate)) {
          cumulativePlanned += planned;
          cumulativePlannedList.push(cumulativePlanned);
        }
        const weekStart = startOfWeek(beginDate, { weekStartsOn: 1 });
        beginDate = addDays(weekStart, 7);
      }

      const graphData = {
        weeks: weeks,
        spent: spentList,
        plannedList: plannedList,
        cumulativeSpent: cumulativeSpentList,
        cumulativeBudget: cumulativeBudgets,
        cumulativePlannedList: cumulativePlannedList,
      };

      // Update props data to show in the graph
      setGraphData(graphData);
    } else {
      while (isBefore(beginDate, new Date())) {
        const weekString = changeDateToWeek(beginDate);
        weeks.push(weekString);
        const budget = weekBudgets.find(
          (weekBudget) => weekBudget.week === weekString
        );
        cumulativeBudget += budget ? budget.value : 0;
        cumulativeBudgets.push(cumulativeBudget);

        const spent = weekSpentList.find(
          (weekSpent) => weekSpent.week === weekString
        );
        spentList.push(spent ? spent.value : 0);
        cumulativeSpent += spent ? spent.value : 0;
        cumulativeSpentList.push(cumulativeSpent);

        cumulativePlannedList.push(null);
        const weekStart = startOfWeek(beginDate, { weekStartsOn: 1 });
        beginDate = addDays(weekStart, 7);
      }

      cumulativePlannedList[cumulativePlannedList.length - 1] = cumulativeSpent;

      // Update props data to show in the graph
      setGraphData({
        weeks: weeks,
        spent: spentList,
        plannedList: [],
        cumulativeSpent: cumulativeSpentList,
        cumulativeBudget: cumulativeBudgets,
        cumulativePlannedList: cumulativePlannedList,
      });
    }
  }, [props.updatedRows, props.inputData, weekBudgets, weekSpentList]);

  return (
    <GraphDiv>
      <div
        style={{ position: 'absolute', top: '20px', right: '20px', zIndex: 1 }}
      >
        <ToggleButtons
          firstButtonText={'Total'}
          secondButtonText={'Weekly'}
          value={totalGraph}
          onChange={handleToggleChange}
        />
      </div>
      {totalGraph ? (
        // If totalGraph is true, meaning that the selected button value is Total, show total line chart
        <LineChart
          margin={{ left: 75, right: 75 }}
          xAxis={[
            {
              label: 'Year - Week',
              data: graphData?.weeks || [],
              scaleType: 'point',
              tickMinStep: 10,
              tickMaxStep: 10,
            },
          ]}
          series={[
            {
              label: 'Budget',
              data: graphData?.cumulativeBudget || [],
              type: 'line',
              connectNulls: true, // Connect data even if not all weeks have budget
              showMark: false, // Do not show point markers
              color: 'var(--twd_aqua)',
            },
            {
              label: 'Spent',
              data: graphData?.cumulativeSpent || [],
              type: 'line',
              connectNulls: true, // Connect data even if not all weeks have spent
              showMark: false, // Do not show point markers
              color: 'var(--twd_navy)',
            },
            {
              label: 'Planned',
              data: graphData?.cumulativePlannedList || [],
              type: 'line',
              connectNulls: true, // Connect data even if not all weeks have planned
              showMark: false, // Do not show point markers
              color: 'var(--twd_sunglow)',
            },
          ]}
        />
      ) : (
        // Else, meaning that the selected button value is Weekly, show weekly bar chart
        <BarChart
          margin={{ left: 75, right: 75 }}
          xAxis={[
            {
              label: 'Year - Week',
              data: graphData?.weeks || [],
              scaleType: 'band', // Adding scaleType for time-like data
            },
          ]}
          series={[
            {
              label: 'Spent',
              data: graphData?.spent || [],
              color: 'var(--twd_navy)',
            },
            {
              label: 'Planned',
              data: graphData?.plannedList || [],
              color: 'var(--twd_sunglow)',
            },
          ]}
        />
      )}
    </GraphDiv>
  );
}

const GraphDiv = styled.div`
  width: calc(50% - 45px);
  padding: 20px;
  margin-left: 5px;
  position: relative;
  background-color: white;
  border-radius: 8px;
  box-shadow: inset 0 0 0 1px var(--twd_web_grey);
`;
