import React, { useEffect, useRef, useState } from 'react';
import '../../styles/Planning.css';
import { LicenseInfo } from '@mui/x-license';
import { styled } from 'styled-components';
import { PlanningProps, RowEntry } from './PlanningType';
import { Graph } from './Graph/Graph';
import { BudgetTable } from './BudgetTable/BudgetTable';

import { NotSavedModal } from '../NotSavedModal/NotSavedModal';
import { useActualCosting, useRows } from './Planning.hooks';
import { EditModeButtons } from './EditModeButtons';
import { PlanningTable } from './PlanningTable/PlanningTable';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LISCENSE_KEY as string);

const StyledDiv = styled.div`
  width: 100%;
`;

const ColumnDiv = styled.div`
  display: flex;
  height: 400px;
  margin-bottom: 10px;
  @media (max-width: 1250px) {
    flex-direction: column;
  }
`;

export function Planning(props: PlanningProps) {
  const shouldTriggerAction = useRef(false);
  const actualCosting = useActualCosting(props.inputData.projectId);
  const { rows } = useRows(
    props.selectedPlanning,
    props.phases,
    props.setIsloading,
    props.inEditMode
  );
  const [updatedRows, setUpdatedRows] = useState<RowEntry[]>([]);
  const [changeIsMade, setChangeIsMade] = useState<boolean>(false);

  useEffect(() => {
    setUpdatedRows(rows);
  }, [rows]);

  const [openNotSavedModal, setOpenNotSavedModal] = useState<boolean>(false);

  useEffect(() => {
    const handleBeforeLeavePage = async (e: any) => {
      e.preventDefault();
      e.returnValue = '';
      shouldTriggerAction.current = true;
    };
    if (props.inEditMode) {
      window.addEventListener('beforeunload', handleBeforeLeavePage);
      document.addEventListener('visibilitychange', handleVisibilityChange);
    } else {
      window.removeEventListener('beforeunload', handleBeforeLeavePage);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeLeavePage);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [props.inEditMode, props.selectedPlanning]);

  const handleVisibilityChange = async () => {
    if (document.visibilityState === 'hidden' && shouldTriggerAction.current) {
      // The user confirmed leaving the page
      if (props.selectedPlanning) {
        await props.leaveEditMode(props.selectedPlanning);
      }
      shouldTriggerAction.current = false; // Reset the flag
    }
  };

  return (
    <StyledDiv>
      <ColumnDiv>
        <BudgetTable
          inputData={props.inputData}
          updatedRows={updatedRows}
          actualCosting={actualCosting}
          phases={props.phases}
          rates={props.rates}
        />
        <Graph
          inputData={props.inputData}
          updatedRows={updatedRows}
          rates={props.rates}
        />
      </ColumnDiv>
      <NotSavedModal
        openNotSavedModal={openNotSavedModal}
        setOpenNotSavedModal={setOpenNotSavedModal}
        selectedPlanning={props.selectedPlanning}
        leaveEditMode={props.leaveEditMode}
        setChangeIsMade={setChangeIsMade}
      />
      <PlanningTableDiv>
        <PlanningTable
          inputData={props.inputData}
          rows={rows}
          setUpdatedRows={setUpdatedRows}
          actualCosting={actualCosting}
          selectedPlanning={props.selectedPlanning}
          setSelectedPlanning={props.setSelectedPlanning}
          phases={props.phases}
          rates={props.rates}
          inEditMode={props.inEditMode}
          setIsloading={props.setIsloading}
          allPlannings={props.allPlannings}
          setAllPlannings={props.setAllPlannings}
          updateMessageQueue={props.updateMessageQueue}
          alreadyInUse={props.alreadyInUse}
          setChangeIsMade={setChangeIsMade}
        />
        {props.selectedPlanning && (
          <EditModeButtons
            updatedRows={updatedRows}
            inEditMode={props.inEditMode}
            alreadyInUse={props.alreadyInUse}
            changeIsMade={changeIsMade}
            setChangeIsMade={setChangeIsMade}
            selectedPlanning={props.selectedPlanning}
            setIsloading={props.setIsloading}
            enterEditMode={props.enterEditMode}
            leaveEditMode={props.leaveEditMode}
            setOpenNotSavedModal={setOpenNotSavedModal}
            currentUser={props.currentUser}
          />
        )}
      </PlanningTableDiv>
    </StyledDiv>
  );
}

const PlanningTableDiv = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  width: calc(100% - 40px);
  box-shadow: inset 0 0 0 1px var(--twd_web_grey);
`;
