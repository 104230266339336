import React from 'react';
import { Button, Modal } from 'twd-react-components';
import { EnterNameModalProps } from './PlanningSelect.types';
import { styled } from 'styled-components';

export function EnterNameModal(props: EnterNameModalProps) {
  const handleModalClick = (event: React.MouseEvent<HTMLElement>) => {
    // This will prevent the click inside the modal from triggering the button click
    event.stopPropagation();
  };

  return (
    <div onClick={handleModalClick} style={{ cursor: 'default', zIndex: 7 }}>
      <Modal
        open={props.openModal}
        onClose={props.closeModal}
        style={{ zIndex: 7 }}
      >
        <ModalInnerDiv>
          <StyledInput
            type="text"
            value={props.value ? props.value : undefined}
            placeholder={'- Enter planning name here -'}
            onChange={props.changePlanningName}
          />
          <Button
            onClick={async () => await props.CreatePlanningButtonOnClick()}
            style={{ background: 't' }}
          >
            {props.buttonName}
          </Button>
        </ModalInnerDiv>
      </Modal>
    </div>
  );
}

const ModalInnerDiv = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
`;

const StyledInput = styled.input<{ isInputFieldSelected?: boolean }>`
  height: 40px;
  text-align: center;
  padding: 3%;
  border: 0;
  outline: none;
  font: inherit;
  background: inherit;
  color: black;
  box-shadow: inset 0 0 0 2px var(--twd_web_grey);
  border-radius: 8px;
  margin-bottom: 10px;

  &::placeholder {
    color: lightgray;
  }
`;
