import React, { useEffect, useState } from 'react';
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import {
  BudgetTableProps,
  initialTableEntry,
  TableRowEntry,
} from './BudgetTableType';
import { PhaseEntry, RowEntry } from '../PlanningType';
import { getFromAPI } from '../../../services/apiCalls';
import { isBefore, isSameWeek, isSameYear, startOfWeek } from 'date-fns';
import { styled } from 'styled-components';
import { formatCurrencyFields } from '../Functions/GridFunctions';

export function BudgetTable(props: BudgetTableProps) {
  const [initialTableEntries, setInitialTableEntries] =
    useState<initialTableEntry>({});
  const [tableRows, setTableRows] = useState<TableRowEntry[]>([]);

  useEffect(() => {
    const GetPhaseBudgets = async () => {
      // Get budget per phase
      const phaseBudgetResponse = await getFromAPI(
        '/budget-planning/phases/cost-estimate/' + props.inputData.projectId
      );
      const phaseBudgets = (await phaseBudgetResponse.json()).data;
      const phaseRows = Object.entries(props.phases).reduce(
        (acc, [phaseCode, phaseName]) => {
          const spent = props.actualCosting.find(
            (actualCost: PhaseEntry) => phaseCode === actualCost.phaseId
          );
          const budget = phaseBudgets.find(
            (phaseBudget: PhaseEntry) => phaseCode === phaseBudget.phaseId
          );
          acc[phaseCode] = {
            id: phaseCode,
            phase: phaseName,
            budget: budget ? budget.value : 0,
            spent: spent ? spent.value : 0,
            planned: 0,
          };
          return acc;
        },
        {} as Record<string, TableRowEntry>
      );
      setInitialTableEntries(phaseRows);
    };
    // Invoke the function to fetch data
    if (props.inputData.projectId !== '') {
      void GetPhaseBudgets();
    }
  }, [props.inputData.projectId, props.phases, props.actualCosting]);

  useEffect(() => {
    const rows = props.updatedRows;
    const initialEntries = initialTableEntries;
    const columnPhaseEntries: { [partId: string]: number } = {};
    const currentWeekDate = startOfWeek(new Date(), { weekStartsOn: 1 });
    if (rows.length > 0) {
      rows.forEach((row: RowEntry) => {
        const {
          id,
          partId,
          part,
          color,
          employee,
          task,
          rate,
          ...columnEntries
        } = row;
        const rowRate = rate ? props.rates[rate] : 1;
        if (!columnPhaseEntries[partId]) {
          columnPhaseEntries[partId] = 0;
        }

        columnPhaseEntries[partId] += Object.entries(columnEntries).reduce(
          (sum, [dateString, columnEntry]) => {
            const [day, month, year] = dateString.split('-');
            let fieldDate = new Date(`${year}-${month}-${day}`);
            fieldDate = startOfWeek(fieldDate, { weekStartsOn: 1 });
            if (isBefore(fieldDate, currentWeekDate)) {
              return sum;
            }
            sum += columnEntry * rowRate;
            return sum;
          },
          0
        ) as number;
        if (initialEntries[partId]) {
          initialEntries[partId].planned = columnPhaseEntries[partId];
        }
      });
    }
    const sortedRows = Object.values(initialEntries).sort((a, b) =>
      a.phase.localeCompare(b.phase)
    );
    setTableRows(sortedRows);
  }, [initialTableEntries, props.updatedRows]);

  const columns: GridColDef<TableRowEntry>[] = [
    {
      field: 'phase',
      headerName: 'Phase',
      type: 'string',
      flex: 3,
      editable: false,
      sortable: true,
    },
    {
      field: 'budget',
      headerName: 'Budget',
      type: 'number',
      flex: 1,
      editable: false,
      sortable: true,
      valueFormatter: (value: number) =>
        value ? formatCurrencyFields(props.inputData.currency, value) : value,
    },
    {
      field: 'spent',
      headerName: 'Spent',
      type: 'number',
      flex: 1,
      editable: false,
      sortable: true,
      valueFormatter: (value: number) =>
        value ? formatCurrencyFields(props.inputData.currency, value) : value,
    },
    {
      field: 'planned',
      headerName: 'Planned',
      type: 'number',
      flex: 1,
      editable: false,
      sortable: true,
      valueFormatter: (value: number) =>
        value ? formatCurrencyFields(props.inputData.currency, value) : value,
    },
  ];

  return (
    <BudgetTableDiv>
      <DataGridPremium
        rows={tableRows}
        columns={columns}
        rowHeight={30}
        disableRowSelectionOnClick
        rowSelection={false}
        hideFooter={true}
        initialState={{
          aggregation: {
            model: {
              budget: 'sum',
              spent: 'sum',
              planned: 'sum',
            },
          }, // Add sum aggregation row
        }}
        sx={{
          border: 0,
          '& .MuiDataGrid-main': { border: 'solid 1px var(--twd_border_grey)' },
          '& .MuiDataGrid-columnHeader': {
            background: 'var(--twd_aqua_pale_2)',
          },
          '& .MuiDataGrid-columnHeaders .MuiDataGrid-filler': {
            background: 'var(--twd_aqua_pale_2)',
          },
          '& .MuiDataGrid-columnHeaders .MuiDataGrid-scrollbarFiller': {
            background: 'var(--twd_aqua_pale_2)',
          },
          '& .MuiDataGrid-aggregationColumnHeaderLabel': {
            display: 'none',
          },
          '& .MuiDataGrid-scrollbar': {
            zIndex: 1,
          },
        }}
      />
    </BudgetTableDiv>
  );
}

const BudgetTableDiv = styled.div`
  width: calc(50% - 45px);
  padding: 20px;
  margin-right: 5px;
  background-color: white;
  border-radius: 8px;
  box-shadow: inset 0 0 0 1px var(--twd_web_grey);
`;
