import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  PhaseCodeEntry,
  PhaseEntry,
  PlanningType,
  RowEntry,
} from './PlanningType';
import { createPlanningEntries } from './Functions/DatabaseFunctions';
import { GridRowId, useGridApiRef } from '@mui/x-data-grid-premium';
import { getFromAPI } from '../../services/apiCalls';

export const useActualCosting = (projectId: string) => {
  const [actualCosting, setActualCosting] = useState<PhaseEntry[]>([]);

  useEffect(() => {
    const updateActualCosting = async () => {
      const response = await getFromAPI(
        '/budget-planning/phases/actual-costing/' + projectId
      );
      const responseJson = await response.json();
      setActualCosting(responseJson.data);
    };

    if (projectId) {
      void updateActualCosting();
    }
  }, [projectId]);

  return actualCosting;
};

export const useRows = (
  selectedPlanning: PlanningType | null,
  phases: PhaseCodeEntry,
  setIsLoading: (isLoading: boolean) => void,
  inEditMode: boolean
) => {
  const [rows, setRows] = useState<RowEntry[]>([]);

  useEffect(() => {
    if (selectedPlanning && Object.keys(phases).length !== 0) {
      setIsLoading(true);
      createPlanningEntries(selectedPlanning, phases).then(async (result) => {
        setRows(result);
        setIsLoading(false);
      });
    } else {
      setRows([]);
    }
  }, [selectedPlanning, inEditMode]);

  return { rows };
};

export const usePlanningUpdates = (
  rows: RowEntry[],
  setUpdatedRows: Dispatch<SetStateAction<RowEntry[]>>,
  setChangeIsMade: Dispatch<SetStateAction<boolean>>
) => {
  const apiRef = useGridApiRef();
  let idCounter = rows.length + 1;

  function addRow(id: GridRowId) {
    const clickedRow: RowEntry | null = apiRef.current.getRow(id);
    if (clickedRow) {
      apiRef.current.updateRows([
        {
          partId: clickedRow.partId,
          part: clickedRow.part,
          id: idCounter,
          rate: 'E',
          employee: '',
          task: 'Enter description here',
        },
      ]);
      setUpdatedRows((oldUpdatedRows) => [
        ...oldUpdatedRows,
        {
          partId: clickedRow.partId,
          part: clickedRow.part,
          id: idCounter,
          rate: 'E',
          employee: '',
          task: 'Enter description here',
        },
      ]);
      idCounter += 1;
    }
  }

  function deleteRow(id: GridRowId) {
    const clickedRow: RowEntry | null = apiRef.current.getRow(id);
    if (clickedRow) {
      const rowsModels = apiRef.current.getRowModels();
      const rows: RowEntry[] = Array.from(rowsModels.values()) as RowEntry[];
      const phaseRows = rows.filter((row) => row.partId === clickedRow.partId);
      if (phaseRows.length < 2) {
        addRow(id);
      }
      apiRef.current.updateRows([{ id: id, _action: 'delete' }]);
      setUpdatedRows((oldUpdatedRows: RowEntry[]) =>
        oldUpdatedRows.filter(
          (oldUpdatedRow: RowEntry) => oldUpdatedRow.id !== id
        )
      );
      setChangeIsMade(true);
    }
  }

  function copyRow(id: GridRowId) {
    const clickedRow: RowEntry | null = apiRef.current.getRow(id);
    if (clickedRow) {
      const { id, ...rest } = clickedRow;
      apiRef.current.updateRows([{ id: idCounter, ...rest }]);
      setUpdatedRows((oldUpdatedRows) => [
        ...oldUpdatedRows,
        { id: idCounter, ...rest },
      ]);
      setChangeIsMade(true);
      idCounter += 1;
    }
  }

  return { apiRef, addRow, deleteRow, copyRow };
};
