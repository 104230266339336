import {
  InputEntry,
  PhaseCodeEntry,
  PhaseEntry,
  PlanningType,
  RowEntry,
} from '../PlanningType';
import { getFromAPI } from '../../../services/apiCalls';
import { PlanningSpent } from '../../../Settings';

export async function getPlanningEntries(
  planningId: string
): Promise<InputEntry[]> {
  const response = await getFromAPI('/budget-planning/entries/' + planningId);
  const responseJson = await response.json();
  return responseJson.data;
}
export async function createPlanningEntries(
  planningId: PlanningType | null,
  phases: PhaseCodeEntry
): Promise<RowEntry[]> {
  if (planningId) {
    const planningEntries = await getPlanningEntries(planningId.id);
    const groupedData: { [key: string]: RowEntry } = {};
    let newId = 0;
    const coveredPhases: string[] = [];

    planningEntries.forEach((entry: InputEntry) => {
      const key = entry.row_id;
      if (!groupedData[key]) {
        groupedData[key] = {
          id: newId++,
          partId: entry.phase_id,
          part: phases[entry.phase_id as string] || 'Unknown Phase',
          employee: entry.employee_id,
          task: entry.task,
          rate: entry.rate,
          color: entry.color && entry.color !== '' ? entry.color : 'no color',
        };
        coveredPhases.push(entry.phase_id);
      }
      groupedData[key][entry.entry_date] =
        (groupedData[key][entry.entry_date] || 0) + entry.hours;
    });
    Object.entries(phases).forEach(([key, value]) => {
      if (!coveredPhases.includes(key)) {
        groupedData[key] = {
          id: newId,
          partId: key,
          part: value,
          task: 'Enter description here',
          rate: 'E',
        };
        newId += 1;
      }
    });
    return Object.values(groupedData).sort((a, b) =>
      a.part.localeCompare(b.part)
    );
  }
  return [];
}

export function convertToDatabase(
  rows: RowEntry[],
  planning_id: string
): InputEntry[] {
  const originalData: InputEntry[] = [];
  let rowId = 0;
  rows.forEach((row) => {
    // Remove all base columns and loop over the date columns
    const { id, partId, part, color, employee, task, rate, ...columnEntries } =
      row;
    let filledInRow: boolean = false;
    Object.keys(columnEntries).forEach((columnName) => {
      const hours = columnEntries[columnName];
      // Only save columns with hours and that are not `spent` columns.
      if (
        typeof hours === 'number' &&
        hours > 0 &&
        columnName !== PlanningSpent
      ) {
        originalData.push({
          planning_id: planning_id,
          phase_id: partId,
          employee_id: employee ? employee : '',
          hours: hours,
          entry_date: columnName,
          task: task ? task : '',
          rate: rate ? rate : '',
          color: color && color !== 'no color' ? color : '',
          row_id: rowId,
        });
        filledInRow = true;
      }
    });
    if (filledInRow) rowId += 1;
  });

  return originalData;
}
