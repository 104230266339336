const apiUrl: string | undefined = process.env.REACT_APP_API;
const apiSecret: string | undefined = process.env.REACT_APP_API_SECRET;

async function connectToAPI(
  api: string,
  method: string,
  body: {} | null = null
) {
  return await fetch(apiUrl + api, {
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': apiSecret as string,
    },
    method: method,
    body: body ? JSON.stringify(body) : null,
  });
}

export async function getFromAPI(api: string) {
  return await connectToAPI(api, 'GET');
}

export async function postFromAPI(api: string, body: {}) {
  return await connectToAPI(api, 'POST', body);
}

export async function putFromAPI(api: string, body: {}) {
  return await connectToAPI(api, 'PUT', body);
}

export async function deleteFromAPI(api: string) {
  return await connectToAPI(api, 'DELETE');
}
