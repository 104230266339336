import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { TabButton } from './TabButton/TabButton';
import { Tab, TabSectionProps } from './tabSectionTypes';

export function TabSection(props: TabSectionProps): ReactElement {
  return (
    <StyledTabSection>
      {props.tabs.map((tab: Tab, index: number) => (
        <TabButton
          key={index}
          name={tab.name}
          isActive={tab.name === props.activeTab}
          onClick={() => props.onTabClick(tab.name)}
          disabled={props.disabled}
        />
      ))}
    </StyledTabSection>
  );
}

const StyledTabSection = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 3rem;
`;
