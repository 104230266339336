import { useEffect, useState } from 'react';
import { putFromAPI } from './services/apiCalls';
import { PlanningType } from './components/Planning/PlanningType';
import { UserType } from './App.types.';

export const useEditMode = (
  selectedPlanning: PlanningType | null,
  setIsLoading: (isLoading: boolean) => void,
  currentUser: UserType | null
) => {
  const [inEditMode, setInEditMode] = useState<boolean>(false);
  const [alreadyInUse, setAlreadyInUse] = useState<boolean>(false);

  useEffect(() => {
    if (selectedPlanning && selectedPlanning.editor) {
      setAlreadyInUse(true);
    } else {
      setAlreadyInUse(false);
    }
  }, [selectedPlanning]);

  async function enterEditMode(selectedPlanning: PlanningType) {
    setIsLoading(true);
    if (currentUser && currentUser.employee) {
      const response = await putFromAPI(
        `/budget-planning/edit/${selectedPlanning.id}/${currentUser.employee}`,
        {}
      );
      if (response.ok) {
        setInEditMode(true);
        console.log('very nice');
      } else {
        setAlreadyInUse(true);
      }
    }
    setIsLoading(false);
  }

  async function leaveEditMode(selectedPlanning: PlanningType) {
    setIsLoading(true);
    const response = await putFromAPI(
      `/budget-planning/remove_edit/${selectedPlanning.id}`,
      {}
    );
    if (response.ok) {
      setInEditMode(false);
      console.log('very nice');
    } else {
      console.log('You have no access');
    }
    setIsLoading(false);
  }

  return { inEditMode, alreadyInUse, enterEditMode, leaveEditMode };
};
