import React, { ChangeEvent, useState } from 'react';
import { PlanningType } from '../../../PlanningType';
import { EnterNameModal } from '../EnterNameModal';
import { styled } from 'styled-components';
import { ReactComponent as PlusIcon } from '../../../../../assets/Plus.svg';
import { AddPlanningSectionProps } from './AddPlanningSection.types';

export function AddPlanningSection<T extends object>(
  props: AddPlanningSectionProps<T>
) {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [planningName, setPlanningName] = useState<string>('');

  const onAddButtonClicked = () => setOpenModal(true);

  const changePlanningName = (event: ChangeEvent<HTMLInputElement>) => {
    setPlanningName(event.target.value);
  };

  const closeModal = () => {
    setPlanningName('');
    setOpenModal(false);
  };

  const CreatePlanningButtonOnClick = async () => {
    const newPlanning: PlanningType | null =
      await props.createPlanning(planningName);
    if (newPlanning) {
      props.setAllPlannings([...props.allPlannings, newPlanning]);
      props.changeSelectedOption(-1);
    }
    props.setIsLoading(false);
    closeModal();
  };

  return (
    <StyledAddPlanningDiv>
      <EnterNameModal
        buttonName={'Create new planning'}
        openModal={openModal}
        closeModal={closeModal}
        changePlanningName={changePlanningName}
        CreatePlanningButtonOnClick={CreatePlanningButtonOnClick}
      />
      <AddButton onClick={onAddButtonClicked}>
        <div style={{ marginRight: '4px' }}>
          <b>New</b>
        </div>
        <PlusIcon />
      </AddButton>
    </StyledAddPlanningDiv>
  );
}

const StyledAddPlanningDiv = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  align-items: end;
  justify-content: end;
`;

const AddButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: inherit;
  border: 0;

  svg {
    fill: var(--twd_web_grey);
  }

  &:hover {
    svg {
      fill: var(--twd_aqua);
    }

    cursor: pointer;
  }
`;
