import { Modal, Button } from 'twd-react-components';
import { styled } from 'styled-components';
import { PlanningType } from '../Planning/PlanningType';

interface NotSavedModalProps {
  openNotSavedModal: boolean;
  setOpenNotSavedModal: (openNotSavedModal: boolean) => void;
  selectedPlanning: PlanningType | null;
  leaveEditMode: (selectedPlanning: PlanningType) => Promise<void>;
  setChangeIsMade: (changeIsMade: boolean) => void;
}

export function NotSavedModal(props: NotSavedModalProps) {
  return (
    <Modal open={props.openNotSavedModal} onClose={() => {}}>
      <InnerModalDiv>
        Are you sure you want to leave this planning without saving?
        <ButtonDiv>
          <Button
            onClick={async () => {
              if (props.selectedPlanning) {
                await props.leaveEditMode(props.selectedPlanning);
                props.setChangeIsMade(false);
                props.setOpenNotSavedModal(false);
              }
            }}
            style={{ marginRight: '10px' }}
          >
            Proceed
          </Button>
          <Button
            onClick={() => {
              props.setOpenNotSavedModal(false);
            }}
          >
            Cancel
          </Button>
        </ButtonDiv>
      </InnerModalDiv>
    </Modal>
  );
}

const InnerModalDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;
