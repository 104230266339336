import React from "react";
import { styled } from "styled-components";

const ContainerStyle = styled.div`
  position: relative;
  width: 7rem;
  height: 7rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--twd_grey);
`;

const CircleStyle = styled.span`
  display: block;
  width: 7rem;
  height: 7rem;
  border: 0.5rem solid var(--twd_web_grey);
  border-top: 0.5rem solid var(--twd_aqua);
  border-radius: 50%;
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 0;
  animation: spinTransition 1s linear infinite;

  @keyframes spinTransition {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export function CircleLoader() {
  return (
    <ContainerStyle>
      <CircleStyle />
    </ContainerStyle>
  );
}
