import { addDays, getISOWeek, getISOWeekYear } from 'date-fns';

export function fromStringToDate(dateString: string): Date {
  return new Date(
    parseInt(dateString.substring(6, 10)), // Year
    parseInt(dateString.substring(3, 5)) - 1, // Month (0-based index)
    parseInt(dateString.substring(0, 2)) // Day
  );
}

export function addDaysToDateString(dateString: string): Date {
  const oldDate = fromStringToDate(dateString);

  // Add 7 days to the date and convert to string
  return addDays(oldDate, 7);
}

export function changeDateToWeek(date: Date): string {
  return `${getISOWeekYear(date)} - ${getISOWeek(date) < 10 ? `0${getISOWeek(date)}` : getISOWeek(date)}`;
}

export function weekStringToDate(weekString: string) {
  const [year, week] = weekString.split('-').map(Number);

  // Set date to the first day of the specified year
  const date = new Date(year, 0, 1);

  // Calculate days to add to reach the start of the specified week
  const days = (week - 1) * 7;

  // Adjust date to start of the week by adding the calculated days
  date.setDate(date.getDate() + days);

  // Adjust to the start of the first week (nearest Monday)
  const dayOfWeek = date.getDay();
  const dayOffset = dayOfWeek <= 4 ? 1 - dayOfWeek : 8 - dayOfWeek;
  date.setDate(date.getDate() + dayOffset);

  return date;
}
