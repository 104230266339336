import { ThemeProvider } from '@mui/material';
import { twdTheme } from './styles/CustomMaterialTheme';
import { styled } from 'styled-components';
import React, { useEffect, useState } from 'react';
import { CustomizedSnackbars } from './components/Errors';
import { Planning } from './components/Planning/Planning';
import { Navbar, getUserProps } from 'twd-react-components';
import { ReportPage } from './components/Report/ReportPage';
import {
  InputDataType,
  MessageQueueType,
  ProjectDataType,
  UpdateInputDataType,
} from './components/Report/LeftColumn/InputType';
import { getFromAPI } from './services/apiCalls';
import {
  PhaseCodeEntry,
  PlanningType,
  RateEntry,
} from './components/Planning/PlanningType';
import { CircleLoader } from './components/CircleLoader/CircleLoader';
import { ChangeAppBar } from './components/ChangeAppBar/ChangeAppBar';
import { TabName } from './components/TabSection/tabSectionTypes';
import { useEditMode } from './App.hooks';
import { UserType } from './App.types.';

const ScreenDiv = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

// Styling for the Box containing the whole app.
const AppDiv = styled.div`
  position: relative;
  height: calc(100% - 85px);
  margin-top: 50px;
  padding: 10px 30px 30px 30px;
  background-color: var(--twd_grey);
  overflow: auto;
`;

const FunctionPlanningDiv = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 1250px) {
    flex-direction: column;
    overflow: auto;
    height: unset;
  }
`;

const FunctionDiv = styled.div`
  gap: 20px;
  display: flex;
  width: 100%;
  height: calc(100% - 92px);
  @media (max-width: 1250px) {
    flex-direction: column;
    overflow: auto;
    height: unset;
  }
`;

const LoaderDiv = styled.div`
  width: 100vw;
  height: calc(100vh - 50px);
  margin-top: 50px;
  z-index: 100;
  background-color: var(--twd_grey);
  opacity: 0.7;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function App() {
  const { profileName } = getUserProps();
  const [currentUser, setCurrentUser] = useState<UserType | null>(null);
  const [messageQueue, setMessageQueue] = useState<MessageQueueType[]>([]);
  const [selectedTab, setSelectedTab] = useState<string>('Planning');
  const [changeInputs, setChangeInputs] = useState(false);
  const [inputData, setInputData] = useState<InputDataType>({
    projectId: '',
    projectNumber: '',
    currency: '',
    weekNumber: '',
    selectedPhases: [],
    allPhases: [],
    planning: { id: '', project_id: '', name: '', editor: '' },
    filename: '',
    startDate: '',
  });
  const [selectedPlanning, setSelectedPlanning] = useState<PlanningType | null>(
    null
  );
  const [projects, setProjects] = useState<ProjectDataType[]>([]);
  const [plannings, setPlannings] = useState<PlanningType[]>([]);
  const [phases, setPhases] = useState<PhaseCodeEntry>({});
  const [rates, setRates] = useState<RateEntry>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [finishedLoadingAppData, setFinishedLoadingAppData] =
    useState<boolean>(false);

  const { inEditMode, alreadyInUse, enterEditMode, leaveEditMode } =
    useEditMode(selectedPlanning, setIsLoading, currentUser);

  useEffect(() => {
    getFromAPI('/budget-report/projects').then(async (response) => {
      const responseJson = await response.json();
      const loadedProjects: ProjectDataType[] = responseJson.data;
      const sortedProjects = loadedProjects.sort((a, b) =>
        (a.ProjectNumber + ' ' + a.ProjectName).localeCompare(
          b.ProjectNumber + ' ' + b.ProjectName
        )
      );
      setProjects(sortedProjects);
      setFinishedLoadingAppData(true);
    });
  }, []);

  useEffect(() => {
    const GetAllProjectData = async () => {
      setIsLoading(true);

      const userResponse = await getFromAPI(`/afas/employee/${profileName}`);

      const userInfoList: UserType[] = (await userResponse.json()).data;
      const userInfo: UserType | null =
        userInfoList.length > 0 ? userInfoList[0] : null;
      // Initialize the phases
      const phasesResponse = await getFromAPI(
        '/budget-planning/phases/' + inputData.projectId
      );
      const allPhases = (await phasesResponse.json()).data;

      // Initialize the rates
      const ratesResponse = await getFromAPI(
        '/budget-planning/rates/' + inputData.projectId
      );
      const allRates = (await ratesResponse.json()).data;

      // Initialize the plannings
      const planningsResponse = await getFromAPI(
        '/budget-planning/' + inputData.projectId
      );
      const allPlannings = (await planningsResponse.json()).data;
      setPlannings(allPlannings);
      setCurrentUser(userInfo);
      setPhases(allPhases);
      setRates(allRates);
      setIsLoading(false);
    };

    if (inputData.projectId) {
      void GetAllProjectData();
    }
  }, [inputData.projectId]);

  const updateInputs = (inputs: UpdateInputDataType) => {
    const updatedInputData: InputDataType = { ...inputData, ...inputs };
    setInputData({ ...inputData, ...inputs });
    // Possible to create new report when projectNumber and weekN umber are available
    setChangeInputs(
      Boolean(updatedInputData.projectNumber) &&
        Boolean(updatedInputData.weekNumber)
    );
  };

  const changeApp = (tab: TabName) => {
    setSelectedTab(tab);
  };

  const updateMessageQueue = (newMessage: MessageQueueType) => {
    setMessageQueue([...messageQueue, newMessage]);
  };

  return (
    <ThemeProvider theme={twdTheme}>
      <ScreenDiv>
        <Navbar
          title={
            selectedTab === 'Planning' ? 'Budget Planning' : 'Budget Report'
          }
        />
        {isLoading && (
          <LoaderDiv>
            <CircleLoader />
          </LoaderDiv>
        )}
        {finishedLoadingAppData && (
          <AppDiv>
            <ChangeAppBar
              projects={projects}
              updateInputs={updateInputs}
              isPlanningSelected={selectedTab}
              changeApp={changeApp}
              inEditMode={inEditMode}
            ></ChangeAppBar>
            {selectedTab === 'Planning' && (
              <FunctionPlanningDiv>
                <Planning
                  inputData={inputData}
                  selectedPlanning={selectedPlanning}
                  setSelectedPlanning={setSelectedPlanning}
                  allPlannings={plannings}
                  setAllPlannings={setPlannings}
                  phases={phases}
                  rates={rates}
                  setIsloading={setIsLoading}
                  updateMessageQueue={updateMessageQueue}
                  inEditMode={inEditMode}
                  alreadyInUse={alreadyInUse}
                  enterEditMode={enterEditMode}
                  leaveEditMode={leaveEditMode}
                  currentUser={currentUser}
                />
              </FunctionPlanningDiv>
            )}
            {selectedTab === 'Reporting' && (
              <FunctionDiv>
                <ReportPage
                  inputData={inputData}
                  updateInputs={updateInputs}
                  changeInputs={changeInputs}
                  setChangeInputs={setChangeInputs}
                  updateMessageQueue={updateMessageQueue}
                  allPlannings={plannings}
                  rates={rates}
                  phases={phases}
                />
              </FunctionDiv>
            )}

            <CustomizedSnackbars
              setMessageQueue={setMessageQueue}
              messageQueue={messageQueue}
            />
          </AppDiv>
        )}
      </ScreenDiv>
    </ThemeProvider>
  );
}

export default App;
