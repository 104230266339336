import { styled } from 'styled-components';
import {
  ProjectDataType,
  UpdateInputDataType,
} from '../Report/LeftColumn/InputType';
import { ProjectNumberInput } from '../ProjectNumber';
import React from 'react';
import { TabSection } from '../TabSection/TabSection';
import { TabName } from '../TabSection/tabSectionTypes';

interface ChangeAppBarProps {
  projects: ProjectDataType[];
  updateInputs: (p: UpdateInputDataType) => void;
  isPlanningSelected: string;
  changeApp: (p: TabName) => void;
  inEditMode: boolean;
}

export function ChangeAppBar(props: ChangeAppBarProps) {
  return (
    <ChangeAppBarDiv>
      <ProjectNumberInput
        updateInputs={props.updateInputs}
        projects={props.projects}
        disabled={props.inEditMode}
      />
      <TabSection
        tabs={[{ name: 'Planning' }, { name: 'Reporting' }]}
        activeTab={props.isPlanningSelected}
        onTabClick={props.changeApp}
        disabled={props.inEditMode}
      />
    </ChangeAppBarDiv>
  );
}

const ChangeAppBarDiv = styled.div`
  width: calc(100% - 40px);
  height: 80px;
  margin-bottom: 10px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 0 20px;
  gap: 8px;
  z-index: 100;
  border-radius: 8px;
  box-shadow: inset 0 0 0 1px var(--twd_web_grey);
`;
