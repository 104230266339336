import { PDFViewer } from './pdfRender';
import { Buttons } from './Buttons';
import React, { useState } from 'react';
import { postFromAPI } from '../../../services/apiCalls';
import { OutputFieldsProps } from './OutputType';
import { styled } from 'styled-components';

const CardDiv = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
`;

export function OutputFields(props: OutputFieldsProps) {
  const [loading, setLoading] = useState(false);
  const [pdfFile, setPdfFile] = useState<{} | null>(null);

  const updateReport = () => {
    setLoading(true);
    props.setChangeInputs(false);
    postFromAPI('/budget-report/create', {
      project_number: props.inputData.projectNumber,
      week_number: props.inputData.weekNumber,
      phases: props.inputData.selectedPhases,
      planning:
        props.inputData.planning.id === '-1' ? null : props.inputData.planning,
      rates: props.rates,
      all_phases: props.phases,
      weeks: props.weeks,
    })
      .then(async (response) => {
        const responseJson = await response.json();
        if (response.ok) {
          setPdfFile(responseJson.data);
        } else {
          props.updateMessageQueue({
            message: responseJson.detail.message,
            kind: 'error',
          });
          setPdfFile(null);
        }
      })
      .catch((error: any) => {
        console.error('Error updating events:', error);
        setPdfFile(null);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <CardDiv>
        <PDFViewer pdfFile={pdfFile} loading={loading} />
      </CardDiv>
      <Buttons
        inputData={props.inputData}
        pdfFile={pdfFile}
        loading={loading}
        changeInputs={props.changeInputs}
        updateReport={updateReport}
        updateMessageQueue={props.updateMessageQueue}
      />
    </>
  );
}
