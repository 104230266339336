import { styled } from 'styled-components';

export const IconButton = styled.button<{
  hoverColor: string;
  normalColor?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: inherit;
  border: 0;
  svg {
    fill: ${(props) => props.normalColor || 'var(--twd_web_grey)'};
  }

  &:hover {
    svg {
      fill: ${(props) => props.hoverColor};
    }
  }
`;
