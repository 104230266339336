import { GridColumnMenuProps } from '@mui/x-data-grid';
import { GridFilterPanel } from '@mui/x-data-grid-premium';
import React from 'react';
import { styled } from 'styled-components';
import { ReactComponent as FilterIcon } from '../../../assets/Filter.svg';

export function CustomColumnMenu(props: GridColumnMenuProps) {
  const { colDef } = props; // Extract column details from props
  const newColumn = () => {
    return colDef.field;
  };

  return <GridFilterPanel getColumnForNewFilter={newColumn} />;
}

export function CustomColumnMenuIcon() {
  return <CustomFilterIcon style={{ width: '60%', fill: 'black' }} />;
}

const CustomFilterIcon = styled(FilterIcon)`
  width: 60%;
  fill: black;
`;
