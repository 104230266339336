import React, {useEffect, useState} from 'react';
import {Document, Page, pdfjs} from 'react-pdf';
import {CircularProgress} from '@mui/material';
import "../../../styles/PDFBox.css";
import FakePDF from "../../../assets/PDFexport.svg";
import BRTStart from "../../../assets/BRT_start.png";
import {PDFViewerProps} from "./OutputType";
import {styled} from "styled-components";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const FakePDFImg = styled.img`
    background-color: white;
`

export function PDFViewer(props: PDFViewerProps) {
    const [numPages, setNumPages] = useState<number>(0);
    const [screenScale, setScreenScale] = useState<number>(window.devicePixelRatio || 1);

    useEffect(() => {
        const updateScreenScale = () => {
            setScreenScale(window.devicePixelRatio || 1);
        };

        window.addEventListener('resize', updateScreenScale);

        return () => {
            window.removeEventListener('resize', updateScreenScale);
        };
    }, []);

    const onDocumentLoadSuccess = ({numPages}: { numPages: number }) => {
        setNumPages(numPages);
    };

    const getPageStyle = (numPages: number) => {
        if (numPages === 1) {
            return 'pdfFirstPage'
        } else {
            return 'pdfPage'
        }
    }

    const scale = 1.3 / screenScale;

    return (
        <>
            {props.loading ? (
                <>
                    <CircularProgress size={"200px"} sx={{position: "absolute", margin: "23%"}}/>
                    <FakePDFImg src={FakePDF} alt=''/>
                </>
            ) : props.pdfFile ? (
                <Document
                    className={"styledDocument"}
                    file={(props.pdfFile as File)}
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    {
                        [...Array(numPages || 0)].map((_, index) => (
                            <Page
                                key={`page_${index + 1}`}
                                className={getPageStyle(numPages)}
                                scale={scale}
                                pageNumber={index + 1}
                                renderTextLayer={false}
                                renderAnnotationLayer={false}
                            />
                        ))
                    }
                </Document>
            ) : (
                <img src={BRTStart} style={{height: "400px", margin: "auto"}} alt=''/>
            )}

        </>
    );
}
